import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import Vant from 'vant'
import { Lazyload } from 'vant'
import { NoticeBar } from 'vant'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import "./utils/rem.js"
import "./assets/iconfont/iconfont.css"
import { Toast } from 'vant';
import { SwipeCell } from 'vant';
// 初始化样式
import '@/assets/css/reset.css'

// 导入vant css(由于采用配置主题方式
import 'vant/lib/index.less'
import 'vant/es/toast/style';
// 全局css
import '@/assets/css/index.scss'
// 这里使用了 lib-flexble 进行 rem 移动端适配
// import 'amfe-flexible'
// 路由权限拦截
import './router/permission'
import '@/assets/font/font.css'

const app = createApp(App)
app.use(router)
app.use(Vant)
app.use(Toast)
app.use(store)
app.use(SwipeCell)
app.use(Lazyload)
app.use(NoticeBar)
app.mount('#app')
