import { router } from '@/router'
import { getToken } from '@/utils/auth'
import $store from '@/store/index'
import { isEmptyObj } from '@/utils/index'

// 检测是否存在用户信息
const checkHasUserInfo = async () => {
  let info = $store.getters['user/info']
  if (isEmptyObj(info)) await $store.dispatch('user/info')
}

// 检测vuex是否存在token
const checkHasToken = async (token) => {
  let vuexToken = $store.getters['user/token']
  if (!vuexToken) $store.commit('user/SET_TOKEN', token)
}

// 白名单
const whiteList = ['/login', '/register', '/forget','/forgetPwd','/home','/preferred','/user1']

router.beforeEach(async (to, from, next) => {
  if (to.query?.pageTypehbui && to.query.pageTypehbui === 'app') {
    window.localStorage.setItem('pageTypehbui', 'app')
  }
  //   next();
  document.title = to.meta.title
  // 获取token
  const token = getToken()
  if (token) {
    // 如果vuex中token不存在，保存一下
    await checkHasToken(token)
    // 如果有token判断是否有用户信息(用户信息建议每次打开网站后重新获取一次最新的用户信息，不建议存本地缓存)
    // 不要用await不然会阻塞下面的代码导致白屏
    await checkHasUserInfo()

    if (to.path === '/login') {
      // 已经登录，跳转到首页
      next({ path: '/user1' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // 其他没有访问权限的页面被重定向到登录页面
      next('/user1')
    }
  }
})
