<template>
    <div class="fo">
        <div class="footer df aic jcsb bsbb">
            <div class="df aic fdc" id="outhome" @click="goTo('/home')">
                <img v-if="arr[0]" src="../../assets/imgs/tab11.svg" style="width:26px;height: 26px">
                <img v-else src="../../assets/imgs/tab10.svg" style="width:26px;height: 26px">
            </div>
            <div class="df aic fdc" id="change" @click="goTo('/c2c')">
                <img v-if="arr[1]" src="../../assets/imgs/tab21.svg" style="width:26px;height: 26px">
                <img v-else src="../../assets/imgs/tab20.svg" style="width:26px;height: 26px">
            </div>
            <div class="df aic jcc center" @click="goTo('/dao')">
                <img src="../../assets/imgs/centers.png">
            </div>
            <div class="df aic fdc jqr" id="wallet" @click="goTo('/wallet')">
                <img v-if="arr[3]" src="../../assets/imgs/tab31.svg" style="width:26px;height: 26px">
                <img v-else src="../../assets/imgs/tab30.svg" style="width:26px;height: 26px">
            </div>
            <div class="df aic fdc" id="my" @click="goTo('/user1')">
                <img v-if="arr[4]" src="../../assets/imgs/tab41.svg" style="width:26px;height: 26px">
                <img v-else src="../../assets/imgs/tab40.svg" style="width:26px;height: 26px">
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router'
const $router = useRouter()
const $route = useRoute()
const arr = reactive([
    true,
    false,
    false,
    false,
    false
])
const list = reactive([
    'outhome',
    'change',
    'center',
    'wallet',
    'my',
])
const lists = reactive([
    '/outhome',
    '/change',
    '/center',
    '/jqr',
    '/my',
])
function goTo(to) {
      $router.push(to)
    }
onMounted(() => {
    watch($route, () => {
        if (lists.includes(location.href.split("#")[1])) {
            list.filter((v, i) => {
                if (v == $route.matched[1].name) {
                    arr[i] = true
                } else {
                    arr[i] = false
                }
            })
        } else {
            console.log(location.href)
        }
    }, {
        immediate: true,
    })
    const divs = document.querySelectorAll('.footer div')
    divs.forEach((div, index) => {
        div.addEventListener("click", () => {
            divs.forEach((v, i) => {
                arr[i] = false
            })
            arr[index] = true
        })
    })
})
</script>

<style lang="scss" scoped>
.fo {
    position: relative;
    position: fixed;
    bottom: 0%;
    width: 100%;
    z-index: 999;

    .footer {
        width: 100%;
        background: url('../../assets/imgs/bg.png') no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 0%;
        padding: 20px 10px 10px;

        >div {
            width: 20%;

            >span {
                color: #8E8C8D;

                &.active {
                    color: #000;
                }
            }
        }

        .center {
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -100%);
                width: 70px;
                height: 70px;

            }
        }
    }

    img {
        width: 26px;
        height: 26px;
        margin-bottom: 10px;
    }

    span {
        font-size: 12px;
    }
}
</style>
