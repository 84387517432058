import axios from 'axios'
import { router } from '@/router'
import { getToken, removeToken } from '@/utils/auth'
import { Notify, Toast, Dialog } from 'vant'
import { token } from '@/setting'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE,
  timeout: 20000
})
// 定义拦截器
instance.interceptors.request.use(config => {
  // console.log('object :>> ', process.env.VUE_APP_BASE, process.env)
  if (getToken()) {
    // 携带登录凭证发起请求
    config.headers[token] = getToken()
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 拦截响应
// instance.interceptors.response.use(response => {
//   const res = response.data
//   // if (res.code !== 200) {
//   //   if (res.code === 401) {
//   //     Dialog.alert({
//   //       title: '系统提示',
//   //       message: res.message
//   //     }).then(() => {
//   //       removeToken()
//   //       router.push({ path: '/home' })
//   //     })
//   //   } else {
//   //     Toast(res.message)
//   //   }
//   //   return Promise.reject(new Error(res.message || '响应未知错误'))
//   // } else {
//   //   return Promise.resolve(res)
//   // }
// }, error => {
//   let msg = error.message
//   if (msg === 'Network Error') {
//     msg = '网络异常'
//   } else if (msg.includes('timeout')) {
//     msg = '系统请求超时'
//   } else if (msg.includes('Request failed with status code')) {
//     msg = '系统接口' + msg.substr(msg.length - 3) + '异常'
//   }
//   Notify({ color: '#ad0000', background: '#ffe1e1', message: msg })
//   return Promise.reject(error)
// })

export default instance
