import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import user from './modules/user'
import goods from './modules/goods'

export default createStore({
  modules: {
    user,
    goods,
  },
  // state: {
  //   OrderLineItem: []
  // },
  // mutations: {
  //   updata(state, payload) {
  //     state.OrderLineItem = payload
  //   }
  // },
  // actions: {
  //   updataSaync(context, payload) {
  //     context.commit('updata', payload)
  //   }
  // },
  // plugins: [createPersistedState({
  //   OrderLineItem: 'OrderLineItem',
  // })]
})
