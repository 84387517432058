import { tokenKey, userInfo } from '@/setting'

export function getToken() {
  return localStorage.getItem(tokenKey)
}

export function setToken(token) {
  return localStorage.setItem(tokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(tokenKey)
}

export function getUserInfo() {
  let user = localStorage.getItem(userInfo)
  return user ? JSON.parse(user) : {}
}

export function setUserInfo(userData) {
  return localStorage.setItem(userInfo, JSON.stringify(userData))
}

export function removeUserInfo() {
  return localStorage.removeItem(userInfo)
}
