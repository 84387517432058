import { setToken, removeToken, setUserInfo } from '@/utils/auth'
import { login, getUserInfo, register, forget } from '@/utils/api.js'

export default {
  namespaced: true,
  state: {
    token: '',
    info: {},
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data
    },
    SET_USER(state, userData = {}) {
      state.info = userData
    },
    CLEAR_USER_INFO(state) {
      state.info = {}
      state.token = null
    },
  },
  actions: {
    async login({ commit }, data) {
      let result = await login(data)
      commit('SET_TOKEN', result.data.token)
      setToken(result.data.token)
    },
    async register({ commit }, data) {
      let result = await register(data)
      commit('SET_TOKEN', result.data.token)
      setToken(result.data.token)
    },
    async forget({ commit }, data) {
      let result = await forget(data)
      commit('SET_TOKEN', result.data.token)
      setToken(result.data.token)
    },
    async info({ commit }) {
      // let result = await getUserInfo()
      // commit('SET_USER', result.data)
      // // 用户信息每次进入都重新获取到session
      // setUserInfo(result.data)
    },
    logout({ commit }) {
      commit('CLEAR_USER_INFO')
      removeToken()
      // removeUserInfo()
    },
  },
  getters: {
    token(state) {
      return state.token
    },
    info(state) {
      return state.info
    },
  },
}
