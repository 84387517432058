/**
 * 判断是否移动设备访问
 */
export function isMobileUserAgent() {
  return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
    window.navigator.userAgent.toLowerCase()
  )
}

/**
 * 判断是否是微信内置浏览器环境
 */
export const isWeixin = () => {
  const ua = navigator.userAgent.toLowerCase()
  const uaMatch = ua.match(/MicroMessenger/i)
  return uaMatch === 'micromessenger'
}

/**
 * 判断对象是否为空对象({})
 * @param obj
 */
export function isEmptyObj(obj) {
  return obj ? Object.keys(obj).length === 0 : false
}
