// 商品vuex模块
// 解构ajax函数
import {
  getShopListMsgReq,
  goodsCategory,
  getGoodsList,
  commodityManagement,
} from "../../utils/api";
const state = {
  shopInfo: {}, // 店铺信息
  goodsList: [],

  // 根据店铺id来保存数据
  merchantList: {},
  // 所有个数大于1的商品
  goodsShopList: [],
  //单机商品数量为一
  goodshopone: [],
  page: 1,
  page_size: 10,
};

// 计算属性
const getters = {
  // 根据分类id获取对应商品
  getMerchantList(state) {
    const arr = [];
    Object.keys(state.merchantList).forEach((item) => {
      state.merchantList[item].forEach((item2, index2) => {
        if (item2.num > 0) {
          arr.push(item2);
        }
      });
    });
    return arr;
  },
  // 获取所有商品
  goodsShopList(state) {
    return state.goodsShopList;
  },
  navList(state) {
    return state.goodsList.map((v) => v.name);
  },
  filterFoodsList(state) {
    const temp = [];
    state.goodsList.forEach((good) => {
      good.foods.forEach((food) => {
        if (food.count > 0) {
          temp.push(food);
        }
      });
    });
    return temp;
  },
  // 获取购物车数据
  filterFoodCount(state, getters) {
    return getters.filterFoodsList.reduce((total, cur) => {
      return total + cur.count;
    }, 0);
  },
  // 获取总价格
  filterFoodPrice(state, getters) {
    return getters.filterFoodsList.reduce((total, cur) => {
      return total + cur.count * cur.price;
    }, 0);
  },
};
// 同步方法
const mutations = {
  // 获取店铺信息
  SET_SHOPINFO(state, data) {
    state.shopInfo = data;
  },
  // 获取商品列表
  SET_GOODSLIST(state, data) {
    state.goodsList = data;
  },
  SET_MERCHANTLIST(state, data) {
    state.merchantList[data.id] = data.list;
  },
  SET_GOODSSHOPLIST(state, data) {
    // const index = state.goodsShopList.findIndex((item) => item.id === data.id);
    // if (index !== -1) {
    // if (data.num === 0) {
    //   state.goodsShopList.splice(index, 1);
    // } else {
    //   state.goodsShopList[index] = data;
    // }
    state.merchantList[data.commodity_management_class_id].forEach((item) => {
      if (item.id === data.id) {
        item.num = data.num;
      }
    });
    // } else {
    //   state.goodsShopList.push({ ...data });
    // }
  },
  //点击商品存储数据到vuex的getMerchantList
  SET_GOODSSHOPLISTS(state, data) {
    console.log(state, '[[');
    console.log(data, 'bbb');
    state.goodsList.push(data)
    // state.goodshopone.push(data)
  },
  // 清空商品
  SET_RESET(state) {
    // state.goodsShopList = [];
    Object.keys(state.merchantList).forEach((item) => {
      state.merchantList[item].forEach((item2, index2) => {
        item2.num = 0;
      });
    });
  },
};
// 异步方法
const actions = {
  // 获取分类id获取列表
  A_getMerchantList({ commit, state }, config) {
    return new Promise((resolve) => {
      if (
        state.merchantList[config.commodity_management_class_id]?.length > 0
      ) {
        resolve([...state.merchantList[config.commodity_management_class_id]]);
      } else {
        commodityManagement(config).then((res) => {
          commit("SET_MERCHANTLIST", {
            list: res.data.list,
            id: config.commodity_management_class_id,
          });
          resolve([
            ...state.merchantList[config.commodity_management_class_id],
          ]);
        });
      }
    });
  },
  // 获取店铺信息 无参数
  A_getShopInfo({ commit }) {
    return new Promise(async (resolve) => {
      // 发送请求
      const res = await getShopListMsgReq({
        page: this.page,
        page_size: this.page_size,
      });
      // 解构数据
      const { data } = res.data;
      // 数据存vuex
      commit("SET_SHOPINFO", data);
    });
  },
  A_getGoodsList({ commit }) {
    return new Promise(async (resolve) => {
      const res = await getGoodsList({
        page: this.page,
        page_size: this.page_size,
      });
      // 解构数据
      const { code, data } = res.data;
      // 数据存vuex
      if (code === 200) {
        // 新定义一个属性
        data.forEach((v) => {
          v.count = 0;
        });
        console.log(data);
        commit("SET_GOODSLIST", data);
        resolve();
      }
    });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
