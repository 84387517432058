/**
 * 全局变量配置
 */
module.exports = {
  // 系统名称
  title: 'yun12',
  // token名称,不要随意修改，要和后端匹配
  token: 'api-token',
  // token在localStorage存储的key的名称
  tokenKey: 'yun12-token',
  // 用户在localStorage存储的key的名称
  userInfo: 'yun12-userInfo'
}
