import { createRouter, createWebHashHistory } from 'vue-router'
import Footer from '../layout/footer/index'
// import Header from "../layout/header/index"

const routes = [
  {
    path: '/',
    name: 'footerMain',
    redirect: '/home',
    component: Footer,
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          title: '首页'
        },
        component: () => import('@/views/home/index')
      },
      {
        path: 'wallet',
        name: 'wallet',
        meta: {
          title: '资产'
        },
        component: () => import('@/views/user/wallet')
      },
      {
        path: 'shop',
        name: 'shop',
        meta: {
          title: '商城'
        },
        component: () => import('@/views/shop/index')
      },
      {
    path: 'c2c',
    name: 'Dex',
    meta: {
      title: 'Dex'
    },
    component: () => import('@/views/user/c2c')
  },
      {
        path: 'dao',
        name: 'dao',
        meta: {
          title: 'DAO'
        },
        component: () => import('@/views/user/dao')
      },
      {
        path: 'shoppingCart',
        name: 'shoppingCart',
        meta: {
          title: '购物车'
        },
        component: () => import('@/views/ShoppingCart/index')
      },
      {
        path: 'activation',
        name: 'activation',
        meta: {
          title: '激活',
          isOpen: true
        },
        component: () => import('@/views/activation/index')
      },
      {
        path: 'stock',
        name: 'stock',
        meta: {
          title: '股权',
          isOpen: true
        },
        component: () => import('@/views/stock/index')
      },
      {
        path: 'fund',
        name: 'fund',
        meta: {
          title: '基金',
          isOpen: true
        },
        component: () => import('@/views/fund/index')
      },
      {
        path: 'currency',
        name: 'currency',
        meta: {
          title: '我的消息',
          isOpen: true
        },
        component: () => import('@/views/currency/index')
      },
      {
        path: 'user',
        name: 'user',
        meta: {
          title: '我的'
        },
        component: () => import('@/views/user/index')
      },
      {
        path: '/user1',
        name: 'user1',
        meta: {
          title: '我的'
        },
        component: () => import('@/views/user/index1'),
      },
      {
        path: 'stockFund',
        name: 'stockFund',
        meta: {
          title: '认养区'
        },
        component: () => import('@/views/stockFund/')
      },
      {
        path: 'shopList',
        name: 'shopList',
        meta: {
          title: '店铺列表'
        },
        component: () => import('@/views/shopList/index')
      }
    ]
  },
  {
    path: '/shopCar',
    name: 'shopCar',
    meta: {
      title: '商家商品页面',
      isOpen: true
    },
    component: () => import('@/views/shopCar/index'),
    children: [
      {
        path: '/shopCar/goods',
        name: 'shopCarGoods',
        component: () => import('@/views/shopCar/goods.vue')
      },
      {
        path: '/shopCar/comment',
        component: () => import('@/views/shopCar/comment.vue')
      },
      {
        path: '/shopCar/shop',
        component: () => import('@/views/shopCar/shop.vue')
      }
    ]
  },
  {
    path: '/all',
    name: 'all',
    meta: {
      title: '订单列表页面',
      isOpen: true
    },
    component: () => import('../views/all/index.vue'),
    children: [
      {
        path: '/all/allOrder',
        component: () => import("../views/all/allOrder.vue")
      },
      {
        path: '/all/obligation',
        component: () => import("../views/all/obligation.vue")
      },
      {
        path: '/all/obligation2',
        component: () => import("../views/all/obligation2.vue")
      },
      {
        path: '/all/waitReceiving',
        component: () => import("../views/all/waitReceiving.vue")
      },
      {
        path: '/all/waitCommend',
        component: () => import("../views/all/waitCommend.vue")
      },
      {
        path: '/all/returnOrder',
        component: () => import("../views/all/returnOrder.vue")
      },
    ]
  },
  {
    path: '/amendPass',
    name: 'amendPass',
    meta: {
      title: '修改密码',
      isOpen: true
    },
    component: () => import('../views/user/amendPass.vue'),
  },
  {
    path: '/amendPass1',
    name: 'amendPass1',
    meta: {
      title: '修改密码',
      isOpen: true
    },
    component: () => import('../views/user/amendPass1.vue'),
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单支付方式',
      isOpen: true
    },
    component: () => import('../views/order/index.vue'),
  },
  {
    path: '/alipay',
    name: 'alipay',
    meta: {
      title: '支付宝支付页面',
      isOpen: true
    },
    component: () => import('../views/order/alipay.vue'),
  },
  {
    path: '/goodDetail',
    name: 'goodDetail',
    meta: {
      title: '商品详情',
      isOpen: true
    },
    component: () => import('../views/shopCar/goodDetail.vue'),
  },
  {
    path: '/afterSale',
    name: 'afterSale',
    meta: {
      title: '售后详情',
      isOpen: true
    },
    component: () => import('@/views/all/afterSale.vue'),
  },

  {
    path: '/cancelOrder',
    name: 'cancelOrder',
    meta: {
      title: '取消订单',
      isOpen: true
    },
    component: () => import('@/views/all/cancelOrder.vue'),
  },
  {
    path: '/amendOrderMsg',
    name: 'amendOrderMsg',
    meta: {
      title: '修改订单信息',
      isOpen: true
    },
    component: () => import('@/views/all/amendOrderMsg.vue'),
  },
  {
    path: '/orderDetail',
    name: 'detail',
    meta: {
      title: '订单详情',
      isOpen: true
    },
    component: () => import('@/views/order/createOrder.vue'),
  },
  {
    path: '/bindCard',
    name: 'bindCard',
    meta: {
      title: '绑定银行卡',
      isOpen: true
    },
    component: () => import('../views/order/bindCard.vue'),
  },
  {
    path: '/setupCenter',
    name: 'setupCenter',
    meta: {
      title: '设置中心',
      isOpen: true
    },
    component: () => import('../views/setup/index.vue'),
  },
  {
    path: '/home/realName',
    name: 'realName',
    meta: {
      title: '实名认证'
    },
    component: () => import('@/views/home/realName')
  },
  {
    path: '/home/pool',
    name: 'pool',
    meta: {
      title: '权益矿池'
    },
    component: () => import('@/views/home/pool')
  },
  {
    path: '/home/per',
    name: 'per',
    meta: {
      title: '优选'
    },
    component: () => import('@/views/per/index')
  },
  {
    path: '/message/index',
    name: 'message',
    meta: {
      title: '幣鏈論壇'
    },
    component: () => import('@/views/message/index')
  },
  {
    path: '/user/message',
    name: 'notic',
    meta: {
      title: '公告中心'
    },
    component: () => import('@/views/user/message')
  },
  {
    path: '/home/RealTime',
    name: 'RealTime',
    meta: {
      title: '数据中心'
    },
    component: () => import('@/views/home/RealTime.vue')
  },
  {
    path: '/activation/buy',
    name: 'buy',
    meta: {
      title: '立即激活'
    },
    component: () => import('@/views/activation/buy')
  },
  {
    path: '/home/intro',
    name: 'intro',
    meta: {
      title: '公司介绍'
    },
    component: () => import('@/views/home/intro')
  },
  {
    path: '/info/info',
    name: 'info',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/info/info')
  },
  //   货币详情
  {
    path: '/info/currencyInfo',
    name: 'currencyInfo',
    meta: {
      title: '详情'
    },
    component: () => import('@/views/info/currencyInfo')
  },
  // 个人中心
  {
    path: '/user/setting',
    name: 'setting',
    meta: {
      title: '用户设置'
    },
    component: () => import('@/views/user/setting')
  },
  {
    path: '/user/c2cDeltail',
    name: 'Deltail',
    meta: {
      title: 'Deltail'
    },
    component: () => import('@/views/user/c2cDeltail')
  },
  {
    path: '/user/stock',
    name: 'stockDetail',
    meta: {
      title: '股权详情'
    },
    component: () => import('@/views/user/stock')
  },
  {
    path: '/user/paymentCode',
    name: 'paymentCode',
    meta: {
      title: '付款码'
    },
    component: () => import('@/views/user/paymentCode')
  },
  {
    path: '/user/fund',
    name: 'fundDetail',
    meta: {
      title: '基金详情'
    },
    component: () => import('@/views/user/fund')
  },
  {
    path: '/user/shucangup',
    name: 'shucangup',
    meta: {
      title: '藏品上传'
    },
    component: () => import('@/views/user/shucangup')
  },
  {
    path: '/user/shucang',
    name: 'shucang',
    meta: {
      title: '数藏图鉴'
    },
    component: () => import('@/views/user/shucang')
  },
  {
    path: '/user/currency',
    name: 'currencyDetail',
    meta: {
      title: '原始股权查询'
    },
    component: () => import('@/views/user/currency')
  },
  {
    path: '/user/kefu',
    name: 'kefu',
    meta: {
      title: '客服中心'
    },
    component: () => import('@/views/user/kefu')
  },
  {
    path: '/user/wenti',
    name: 'wenti',
    meta: {
      title: '常见问题'
    },
    component: () => import('@/views/user/wenti')
  },
  {
    path: '/user/setPwd',
    name: 'setPwd',
    meta: {
      title: '修改登录密码'
    },
    component: () => import('@/views/user/setPwd')
  },
  {
    path: '/user/setPayPwd',
    name: 'setPayPwd',
    meta: {
      title: '修改支付密码'
    },
    component: () => import('@/views/user/setPayPwd')
  },
  {
    path: '/user/bankCard',
    name: 'bankCard',
    meta: {
      title: '绑定银行卡'
    },
    component: () => import('@/views/user/bankCard')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      isOpen: true
    },
    component: () => import('@/views/login')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/register'),
    hidden: true
  },
  {
    path: '/forget',
    name: 'forget',
    meta: {
      title: '忘记密码'
    },
    component: () => import('@/views/forget'),
    hidden: true
  },
  {
    path: '/user/recharge',
    name: 'recharge',
    meta: {
      title: '充值'
    },
    component: () => import('@/views/user/recharge')
  },
  {
    path: '/user/Recharge1',
    name: 'Recharge1',
    meta: {
      title: '充值'
    },
    component: () => import('@/views/user/Recharge1')
  },
  {
    path: '/user/myCommend',
    name: 'myCommend',
    meta: {
      title: '我的评价'
    },
    component: () => import('@/views/user/myCommend')
  },
  {
    path: '/user/bscRecharge',
    name: 'bscRecharge',
    meta: {
      title: '充值'
    },
    component: () => import('@/views/user/bscRecharge')
  },
  {
    path: '/user/detailed',
    name: 'detailed',
    meta: {
      title: '我的明细'
    },
    component: () => import('@/views/user/detailed')
  },
  {
    path: '/user/detailedroc',
    name: 'detailedroc',
    meta: {
      title: '我的明细'
    },
    component: () => import('@/views/user/detailedroc')
  },
  {
    path: '/user/detailedshouyi',
    name: 'detailedshouyi',
    meta: {
      title: '我的明细'
    },
    component: () => import('@/views/user/detailedshouyi')
  },
  {
    path: '/user/address',
    name: 'address',
    meta: {
      title: '地址管理'
    },
    component: () => import('@/views/user/address')
  },
  {
    path: '/user/showAllMycommend',
    name: 'showAllMycommend',
    meta: {
      title: '我的评价'
    },
    component: () => import('@/views/user/showAllMycommend')
  },
  {
    path: '/user/editMyCommend',
    name: 'editMyCommend',
    meta: {
      title: '编辑我的评价'
    },
    component: () => import('@/views/user/editMyCommend')
  },
  {
    path: '/user/evaluateContent',
    name: 'evaluateContent',
    meta: {
      title: '写评价'
    },
    component: () => import('@/views/user/evaluateContent')
  },
  {
    path: '/user/upto',
    name: 'upto',
    meta: {
      title: '提现'
    },
    component: () => import('@/views/user/upto')
  },
  {
    path: '/user/withdraw',
    name: 'withdraw',
    meta: {
      title: '提现'
    },
    component: () => import('@/views/user/withdraw')
  },
  {
    path: '/user/bankCard',
    name: 'bankCard',
    meta: {
      title: '银行卡'
    },
    component: () => import('@/views/user/bankCard')
  },
  {
    path: '/user/rechargeLog',
    name: 'rechargeLog',
    meta: {
      title: '购买记录'
    },
    component: () => import('@/views/user/rechargeLog')
  },
  {
    path: '/user/activationLog',
    name: 'activationLog',
    meta: {
      title: '激活记录'
    },
    component: () => import('@/views/user/activationLog')
  },
  {
    path: '/user/income',
    name: 'income',
    meta: {
      title: '收支明细'
    },
    component: () => import('@/views/user/income')
  },
  {
    path: '/user/invitation',
    name: 'invitation',
    meta: {
      title: '邀请好友'
    },
    component: () => import('@/views/user/invitation')
  },
  {
    path: '/message/messageDetails',
    name: 'messageDetails',
    meta: {
      title: '消息详情'
    },
    component: () => import('@/views/message/messageDetails')
  },
  {
    path: '/user/problem',
    name: 'problem',
    meta: {
      title: '常见问题'
    },
    component: () => import('@/views/user/problem')
  },
  {
    path: '/user/team',
    name: 'team',
    meta: {
      title: '我的团队'
    },
    component: () => import('@/views/user/team')
  },
  {
    path: '/user/setting',
    name: 'setting',
    meta: {
      title: '设置中心'
    },
    component: () => import('@/views/user/setting')
  },
  {
    path: '/user/product',
    name: 'product',
    meta: {
      title: '股权证书'
    },
    component: () => import('@/views/user/product')
  },
  {
    path: '/user/currencySwitch',
    name: 'currencySwitch',
    meta: {
      title: '幸运积分互转'
    },
    component: () => import('@/views/user/currencySwitch')
  },
  {
    path: '/user/jifenhuzhuan',
    name: 'jifenhuzhuan',
    meta: {
      title: '消费积分互转'
    },
    component: () => import('@/views/user/jifenhuzhuan')
  },
  {
    path: '/user/bindInviteCode',
    name: 'bindInviteCode',
    meta: {
      title: '绑定推荐人'
    },
    component: () => import('@/views/user/bindInviteCode')
  },
  {
    path: '/user/exchange2',
    name: 'exchange2',
    meta: {
      title: '积分换仓单'
    },
    component: () => import('@/views/user/exchange2')
  },
  {
    path: '/user/exchange1',
    name: 'exchange1',
    meta: {
      title: '仓单换积分'
    },
    component: () => import('@/views/user/exchange1')
  },
  {
    path: '/user/currencyRealization',
    name: 'currencyRealization',
    meta: {
      title: '币种变现'
    },
    component: () => import('@/views/user/exchange1')
  },
  {
    path: '/user/currencySwitchLog',
    name: 'currencySwitchLog',
    meta: {
      title: '转账记录'
    },
    component: () => import('@/views/user/currencySwitchLog')
  },
  {
    path: '/stockFund/video',
    name: 'video',
    meta: {
      title: '视频'
    },
    component: () => import('@/views/stockFund/video')
  },
  {
    path: '/stockFund/xymh',
    name: 'xymh',
    meta: {
      title: '幸运魔盒'
    },
    component: () => import('@/views/stockFund/xymh')
  },
  {
    path: '/user/myCurrency',
    name: 'myCurrency',
    meta: {
      title: '我的货币'
    },
    component: () => import('@/views/user/myCurrency')
  },
  {
    path: '/user/bmikeceSwitch',
    name: 'bmikeceSwitch',
    meta: {
      title: '余额互转'
    },
    component: () => import('@/views/user/bmikeceSwitch')
  },
  {
    path: '/user/myShop',
    name: 'myShop',
    meta: {
      title: '我要开店'
    },
    component: () => import('@/views/user/myShop')
  },
  {
    path: '/user/shopManage',
    name: 'shopManage',
    meta: {
      title: '店铺管理'
    },
    component: () => import('@/views/user/shopManage')
  },
  {
    path: '/user/goodManage',
    name: 'goodsManage',
    meta: {
      title: '商品管理'
    },
    component: () => import('@/views/user/goodManage')
  },
  {
    path: '/user/goodEdit',
    name: 'goodEdit',
    meta: {
      title: '商品编辑'
    },
    component: () => import('@/views/user/goodEdit')
  },
  {
    path: '/user/addGood',
    name: 'addGood',
    meta: {
      title: '添加商品'
    },
    component: () => import('@/views/user/addGood')
  },
  {
    path: '/ticketsBag',
    name: 'ticketsBag',
    meta: {
      title: '券包'
    },
    component: () => import('@/views/ticketsBag/index')
  },


  // 以下这些页面采用统一的头部
  // {
  //   path: "/",
  //   name: "headerMain",
  //   redirect: "/home",
  //   component: Header,
  //   children: [
  //     {
  //       path: '/user/about',
  //       name: 'about',
  //       meta: {
  //         title: "关于我们",
  //       },
  //       component: () => import('../views/user/about')
  //     }
  //   ],
  // },
  {
    path: '/redirect',
    name: 'redirect',
    meta: {
      title: 'Redirect',
      hideHeader: true,
      isOpen: true
    },
    component: () => import('@/views/redirect/index')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BAER_URL), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes
})

export { router }
